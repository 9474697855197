import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  blueText: {
    fontSize: '16px',
    color: theme.palette.primary.blue,
    fontWeight: 700,
  },
  orangeText: {
    fontSize: '16px',
    color: theme.palette.primary.orange,
    fontWeight: 700,
  },
  orangeTextPrice: {
    fontSize: '18px',
    color: theme.palette.primary.orange,
    fontWeight: 700,
  },
  blueTextPrice: {
    fontSize: '18px',
    color: theme.palette.primary.blue,
    fontWeight: 700,
  },
  boldTitle: {
    fontSize: theme.fontSizes.bodyText,
    fontWeight: 700,
    color: theme.palette.primary.blue,
  },
  regularText: {
    fontSize: theme.fontSizes.bodyText,
    color: theme.palette.primary.blue,
  },
  blueTextUnderlined: {
    fontSize: '18px',
    color: theme.palette.primary.blue,
    marginRight: '10px',
    textDecoration: 'line-through',
  },
  tableHeaderTableCell: {
    backgroundColor: '#f1f1f1',
    padding: '0px',
    minHeight: '72px',
    fontSize: theme.fontSizes.tableHeader,
  },
  verticalDivider: {
    margin: '10px 20px 10px 16px',
    border: '0.5px solid',
    borderColor: theme.palette.primary.blue,
  },
  boldBlueNumber: {
    fontSize: '25px',
    color: theme.palette.primary.blue,
    fontWeight: 700,
    width: '60px',
    padding: '0px 0px 0px 12px',
  },
  minusSymbol: {
    margin: '5px 12px 0px 0px',
    color: theme.palette.primary.orange,
  },
  plusSymbol: {
    margin: '5px 0px 0px 0px',
    color: theme.palette.primary.orange,
  },
  blueSVG: {
    color: theme.palette.primary.blue,
  },
  blueSearchIcon: {
    color: theme.palette.primary.blue,
    fontSize: '32px',
  },
  blueButton: {
    backgroundColor: theme.palette.primary.blue,
    color: 'white',
    minWidth: '40px',
    maxWidth: '40px',
    height: '40px',
    padding: '0px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '8px',
    '& span': {
      maxHeight: '40px',
    },
  },
  fontSizeButton: {
    fontSize: '35px',
    fontWeight: 700,
  },
  svgIconContainer: {
    marginRight: '5px',
    position: 'relative',
  },
  cartCircle: {
    backgroundColor: theme.palette.primary.orange,
    height: '23px',
    width: '23px',
    borderRadius: '12px',
    position: 'absolute',
    right: '-9px',
    top: '-7px',
    color: 'white',
    fontSize: '14px',
    fontWeight: 700,
    textAlign: 'center',
  },
  tableCell: {
    width: '100%',
    padding: '24px 8px 24px 18px',
  },
  tableCellRight: {
    padding: '24px 56px 24px 0px',
  },
  tableCellLeft: {
    padding: '24px 100px 24px 36px',
  },
  tableRowBody: {
    margin: '0px 8px',
  },
  blueTextPriceCustom: {
    fontSize: '16px',
    color: theme.palette.primary.blue,
    fontWeight: 500,
  },
  priceBox: {
    wordSpacing: '2px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  priceTextField: {
    width: '150px',
    padding: '10px 8px',
    '& input': {
      textAlign: 'left',
      fontSize: '18px',
      fontWeight: 700,
      color: theme.palette.primary.blue,
    },
  },
  dollarSign: {
    color: theme.palette.primary.blue,
  },
  orangeIcon: {
    color: theme.palette.primary.orange,
    marginRight: '3px',
    marginLeft: '3px',
  },
  bluePriceTextTitle: {
    color: theme.palette.primary.blue,
    fontWeight: 700,
    fontSize: '22px',
  },
  customPrice: {
    fontSize: '18px',
    color: theme.palette.primary.blue,
    fontWeight: 700,
    marginRight: '3px',
  },
  tableBodyContainer: {
    '& .MuiTableBody-root': {
      height: '100%',
    },
  },
  seeMoreTypography: {
    color: theme.palette.primary.orange,
    fontSize: '16px',
  },
  rightAlignButton: {
    textAlign: 'right',
  },
  loadMoreProductsButton: {
    textTransform: 'Capitalize',
    color: theme.palette.primary.orange,
    fontSize: '16px',
  },
  minWidth: {
    minWidth: '152px',
  },
}));

export default useStyles;
