import React, { useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';

import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
// import { CheckCircle as CheckIcon } from 'react-feather';
import { TRANSBANK, COMPRAQUI } from '../../constants/payment';
import useStyles from './styles';
import {
  resetCustomer, addCustomer, resetDebtsArray, resetDebtPaymentObj,
} from '../../actions/customerActions';
import {
  resetOrderId,
  resetRDCMachine,
  resetStore,
  resetTimesError,
} from '../../actions/sessionActions';
import { logout } from '../../actions/accountActions';
import { resetCart, resetPaymentsCart } from '../../actions/cartActions';
import { resetAllProductStock } from '../../actions/productActions';
import CustomButton from '../../components/Material/CustomButton';
import { resetProductsToReturn, resetReturnsBill } from '../../actions/returnsActions';
import Logo from '../../components/Layout/Logo';

function StoreOptions() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { clientManagement } = useSelector((s) => s.session.moduleInformation);

  const { selectedStore } = useSelector((state) => state.session);

  const handleClientType = () => {
    if (!clientManagement) {
      dispatch(addCustomer(null));
      history.push('/sale');
    } else {
      history.push('/store-options/client-type');
    }
  };

  const handleReturns = () => {
    history.push('/store-options/returns');
  };

  const handleEnterStock = () => {
    history.push('/store-options/enter-stock');
  };

  const handleCreditSale = () => {
    history.push('/store-options/credit-sale');
  };

  const handleLogout = () => {
    const resetAll = () => {
      dispatch(resetTimesError());
      dispatch(resetStore());
      dispatch(resetCustomer());
      dispatch(resetRDCMachine());
      dispatch(resetCart());
      dispatch(logout());
    };
    resetAll();
    history.replace('/');
  };

  useEffect(() => {
    // Cada vez que llegue a las opciones de tienda debiese eliminar al customer de redux,
    // ademas de resetear las veces de error, y el cart
    dispatch(resetCustomer());
    dispatch(resetTimesError());
    dispatch(resetAllProductStock());
    dispatch(resetOrderId());
    dispatch(resetCart());
    dispatch(resetDebtsArray());
    dispatch(resetDebtPaymentObj());
    dispatch(resetReturnsBill());
    dispatch(resetProductsToReturn());
    dispatch(resetPaymentsCart());
  }, []);

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      flex={1}
    >
      <Box
        mb={6}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent='center'
      >
        <Logo imgUrl="/img/logos/logo_color.svg" className={classes.logoSipos} />
        {window.SmartPOS
          && window.SmartPOS.getAdquirente() === COMPRAQUI
          ? (<Logo imgUrl="/img/logos/logo_compraqui.png" className={classes.logo} />) : null}
        {window.SmartPOS
          && window.SmartPOS.getAdquirente() === TRANSBANK
          ? (<Logo imgUrl="/img/logos/logo_tbk.svg" className={classes.logo} />) : null}
      </Box>
      <Box>
        <Typography
          component="span"
          variant="h2"
          className={classes.titleTypography}
        >
          {selectedStore.name ? selectedStore.name : 'Kiosko Default'}
        </Typography>
      </Box>
      <Box
        mt={3}
        width='100%'
        display='flex'
        flexDirection='column'
        alignItems='center'
      >
        <Box
          width='100%'
          mb={2}
        >
          <CustomButton
            type="submit"
            fullWidth
            variant="contained"
            color='primary'
            onClick={handleClientType}
            buttoncolor='orange'
          >
            Venta
          </CustomButton>
        </Box>
        <Box
          width='100%'
          mb={2}
        >
          <CustomButton
            type="submit"
            fullWidth
            variant="contained"
            color='primary'
            onClick={handleReturns}
            buttoncolor='blue'
          >
            Devoluciones
          </CustomButton>

        </Box>
        <Box
          width='100%'
          mb={2}
        >
          <CustomButton
            type="submit"
            fullWidth
            variant="contained"
            color='primary'
            onClick={handleEnterStock}
            buttoncolor='blue'
          >
            Ingresar Stock
          </CustomButton>
        </Box>
        <Box
          width='100%'
          mb={2}
        >
          <CustomButton
            type="submit"
            fullWidth
            variant="contained"
            color='primary'
            onClick={handleCreditSale}
            buttoncolor='blue'
          >
            Fiado
          </CustomButton>
        </Box>

      </Box>
      <Box
        onClick={handleLogout}
        mt={4}
      >
        <Typography
          className={classes.logoutTypography}
        >
          Cerrar Sesión
        </Typography>
      </Box>
    </Box>
  );
}

export default StoreOptions;
