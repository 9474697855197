import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    minHeight: '100%',
    flexDirection: 'column',
    paddingBottom: 80,
    paddingTop: 80,
    '& a': {
      textAlign: 'center',
      display: 'block',
      color: theme.palette.primary.white,
      textDecoration: 'none',
      fontSize: theme.fontSizes.bodyText,
    },
  },
  logoTitle: {
    fontWeight: 700,
    color: theme.palette.primary.orange,
    letterSpacing: '1.5px',
  },
  titleTypography: {
    color: theme.palette.primary.blue,
    fontWeight: 700,
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  logoutTypography: {
    color: theme.palette.primary.blue,
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.orange,
    },
  },
  checkIcon: {
    color: theme.palette.primary.blue,
  },
  iconContainer: {
    height: '50px',
    width: '50px',
    marginBottom: '10px',
  },
  logo: {
    minWidth: '100px',
    minHeight: '50px',
    maxWidth: '150px',
    maxHeight: '150px',
    marginBottom: '10px',
  },
  logoSipos: {
    height: '150px',
    width: '150px',
  },
}));

export default useStyles;
