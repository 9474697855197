/* eslint-disable import/prefer-default-export */
import axios from '../../utils/axios';

const postNewRedelcomPayment = async (redelcomObject) => {
  const response = await axios.post('/api/payment/redelcom/new', redelcomObject);
  return response.data;
};

const postSendBillEmail = async (body) => {
  const response = await axios.post('/api/payment/send-bill', body);
  return response.data;
};

const postNewPayment = async (postObject) => {
  const response = await axios.post('/api/payment/create', postObject);
  return response.data;
};

const putPayment = async (putObject) => {
  const response = await axios.put('/api/payment/edit', putObject);
  return response.data;
};

const patchPaymentResponse = async (patchObject) => {
  const response = await axios.patch('/api/payment/response', patchObject);
  return response.data;
};

export {
  postNewRedelcomPayment,
  postSendBillEmail,
  postNewPayment,
  putPayment,
  patchPaymentResponse,
};
